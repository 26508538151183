import { useEffect, useState } from "react"

export function withButtonClick(Component): React.ComponentType {
    console.log("Override is applied!")

    const handleClick = async (e) => {
        e.preventDefault() // Prevent default form behavior

        // Get the parent element (the form container) and its child inputs
        const formElement = e.target.closest("form") // Find the closest form element
        const formData = {}

        if (formElement) {
            const inputs = formElement.querySelectorAll(
                "input, select, textarea"
            ) // Get all inputs

            // Loop through all input fields and collect their names and values
            inputs.forEach((input) => {
                if (input.name) {
                    formData[input.name] = input.value
                }
            })
        }

        console.log("Collected form data:", formData)

        try {
            const response = await fetch(
                "https://x8ki-letl-twmt.n7.xano.io/api:t36AF76y/Testing_Submission",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                    credentials: "include", // Ensures cookies are sent/received
                }
            )

            if (response.ok) {
                alert("Form submitted successfully!")
            } else {
                alert("Form submission failed.")
            }
        } catch (error) {
            console.error("Error submitting the form:", error)
        }
    }

    return (props) => {
        return <Component {...props} onClick={handleClick} />
    }
}
